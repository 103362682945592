import React from 'react';
import { Helmet } from 'react-helmet-async';

const PlansAndTerms = () => {
  return (
    <div className="container mt-5">
      <Helmet>
  <title>Terms & Conditions - SR Tutors Bureau</title>

  <meta
    name="description"
    content="Review the Terms & Conditions for using the services provided by SR Tutors Bureau. These terms outline the rules and guidelines for using our website and tutoring services."
  />

  <meta
    name="keywords"
    content="terms and conditions, SR Tutors Bureau, user agreement, website terms, tutoring services, policies, rules"
  />

  <link rel="canonical" href="https://www.srtutorsbureau.com/terms-and-conditions" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="SR Tutors Bureau" />
  <meta name="publisher" content="SR Tutors Bureau" />
</Helmet>

      <h1 className="text-center mb-4">Membership Plans</h1>

      <section className="mb-5">
        <h3 className="text-danger">Online Plan</h3>
        <ul className="list-group">
          <li className="list-group-item">Membership minimum starts with Rs. 500.</li>
          <li className="list-group-item">Membership fee is non-refundable.</li>
          <li className="list-group-item">The validity of the membership depends on the chosen plan.</li>
          <li className="list-group-item">Plans can be changed without prior notice.</li>
          <li className="list-group-item">The bureau is not responsible for any inconvenience on either the tutors' or parents' side.</li>
          <li className="list-group-item">Tutors will benefit from the plan according to the option selected.</li>
        </ul>
      </section>

      <section className="mb-5">
      <h3 className="text-danger">Offline Plan</h3>
        <ul className="list-group">
          <li className="list-group-item">Registration fee: Rs. 500 for one year, Rs. 1000 for five years, and Rs. 1500 for a lifetime.</li>
          <li className="list-group-item">The bureau will charge 50% in the first month and 10% for subsequent months.</li>
          <li className="list-group-item">Advance payment will be collected from the party and paid to the teachers/tutors after the month ends.</li>
          <li className="list-group-item">The bureau is not responsible for teacher performance and reserves the right to forfeit payments after a couple of trial classes.</li>
          <li className="list-group-item">Tutors will continue classes as long as the bureau deems necessary.</li>
          <li className="list-group-item">Tutors/Teachers must submit all necessary documents (address proof, photo ID, photographs, qualification proof, etc.).</li>
          <li className="list-group-item">We are solely a home tuition service provider and are not liable for any inconvenience or mishaps involving parents and tutors/teachers.</li>
          <li className="list-group-item">If any class is discontinued due to tutor negligence, the bureau has the right to take 50% as a part-month commission.</li>
          <li className="list-group-item">Tutors/Teachers must inform the bureau whenever a class is discontinued.</li>
          <li className="list-group-item">Registration fees are non-refundable once a class is received from the bureau, whether successful or not.</li>
          <li className="list-group-item">Only if the bureau fails to provide a class within 60 days, 75% of the registration fee will be refundable.</li>
          <li className="list-group-item">All disputes are subject to the jurisdiction of Karkardooma Court, Delhi.</li>
        </ul>
      </section>

      <section className="mb-5">
        <h3 className="text-danger">Terms and Conditions</h3>
        
        <div class="tc-list">
				    
					  <li>Srtutorbureau.com collects information / data including e-mail id, mail address, first name, last name, telephone number, date of birth, education profile, age, gender, ethnic background, appearance, religion, occupation, preferences, life style information, general geographical location from its visitors / users in order to create their unique profiles. Apart from this, certain other information including physical and mental disabilities are also collected. The said information / data collected is entered in the programme especially devised for matching profiles and which programme enable to provide matches for the visitors / users based on their pre-selected criteria. Srtutorbureau.com does not display on the site all information / data collected from its visitors / users. Certain private information is only displayed after taking prior consent of the visitors / users.</li>
					  <li>he user undertakes not to duplicate, download publish, modify and distribute material on Srtutorbureau.com unless specifically authorized by Srtutorbureau.com in this regard. The user undertakes not to establish any deep link or other connection to any specific page of Srtutorbureau.com other than the Home Page without obtaining prior consent of Srtutorbureau.com.</li>
					  <li>The user undertakes to use Srtutorbureau.com for his/her own purposes. Using content from Srtutorbureau.com for derivative works with a commercial motive without prior written consent from Srtutorbureau.com is strictly prohibited.</li>
					  <li>Srtutorbureau.com uses technological means to exclude Robots etc from crawling the website and scraping content. The user undertakes not to circumvent these methods.</li>
					  <li>	Any advice, counseling, recommendations or information provided at Srtutorbureau.com including information of Srtutorbureau.com members may not be necessarily correct, true or reliable, and that any reliance placed thereon and any action taken on the basis thereof shall be entirely / solely at the risk of the person or persons placing such reliance or taking such action. Visitors wishing to use the information so provided are advised to conduct their own due diligence, in respect of the site content sought to be taken advantage of, at their own initiative, cost and effort. Srtutorbureau.com does not advise / recommend its users / visitors to establish any contact / interaction with any other user / member / visitor or any such Profile(s) as displayed in the website based on the data / information as furnished in such users profile / photograph etc. and the user / visitor opting to build contact or interact shall do it solely at his / her own risk. Srtutorbureau.com cannot be held responsible or liable, in any manner whatsoever, in respect of any reliance placed on any information displayed / placed on the website, since the same purports merely to act as indicators of the general scenario in the related behalf.</li>
					  
					  <li>Srtutorbureau.com is an on-line service provider for its members/visitors to create their own/unique profiles envisaging certain personal (optional) information(s) are to be displayed on the net/web-site, which information is used by visitors for searching and matching purposes. Srtutorbureau.com does not sell, rent, share, trade or give away any of your personal information except which is displayed and intended to be shared by its users / members / visitors displayed on site. Although Srtutorbureau.com shall take all reasonable precautions, Srtutorbureau.com shall not be responsible in case such public information / photograph of the member/user so displayed in the site is being unauthorized copied / printed / published / forged / superimposed / manipulated etc. or misused by any user / member / visitor. The user / member shall indemnify Srtutorbureau.com from all kind of loss / damages which Srtutorbureau.com may suffer from any activities carried out by any visitor/user/member through improper use of the service provided by Srtutorbureau.com • By listing a profile, or a business or personal listing or a link, or any content or listing, on Srtutorbureau.com, the person or persons making or posting such listing, and the person or persons responsible for such posting or listing, shall be deemed to have agreed to hold Srtutorbureau.com harmless and protected against any suit or complaint, civil, pecuniary or criminal, and against any claims or damages (including, without limitation, interests, fines and penalties) which may be brought against or levied on Srtutorbureau.com on account of any matter arising out of, or relating to, such posting / listing or response received from such posting/listing.</li>
				      
					  <li>Srtutorbureau.com does not represent or endorse the accuracy or reliability of any member / visitor profile, advice, opinion, statement or other information displayed / uploaded through the service by Srtutorbureau.com or any of its owners, staff or agents are not responsible under any circumstances for any action/display whether direct or indirect, which occurs as a result of online display of information in the nature of illicit material / material that may be offensive to visitors / members or is patently offensive to the on-line community or any material which may promote racism, bigotry, hatred or physical/mental harm of any kind to any group or individual or transmit any chain letters or junk mail or unsolicited mass mailing or spamming or obscene material to other users / members. Srtutorbureau.com does not monitor the conduct of its users/members or visitors and therefore Srtutorbureau.com does not take the responsibility of any conduct by its users / members or visitors who may avail / obtain / use / exploit the on-line service provided by Srtutorbureau.com in order to harass, abuse or harm another user/member or visitor or use the said on-line service to promote / advertise / solicit anything without their prior consent or an act in any manner which may threaten the safety of users / members.</li>
					  
					  <li>Srtutorbureau.com reserves the right to remove / delete any content / message / photo (s) / profile or cancel the registration / membership of such individual / user either upon a complaint received from another member / user / visitor / individual / group of individuals or upon discovery of the same on its own or based on its sole judgment and perception and it shall without notice stop providing the service entitled to a visitor/user / member and forfeit all other incidental service(s) with immediate effect along with the fee/tenure of registration as well as take appropriate legal action against such visitor / user / member. By listing a profile on the website, it is deemed that the said member/user has an intention to get teach and in case it appears to Srtutorbureau.com that at the time of listing the profile or subsequently such user / visitor had no intention to enter into a teacher and student alliance or has changed his/her intention, then Srtutorbureau.com shall reserve the right to remove / delete such profile from the website and initiate prosecution under the law or take such steps as may be advised from time to time. In using the website, it is deemed that the user/member agree not to post any content or views that may be considered threatening, abusive, vulgar, obscene or otherwise objectionable or act illegally or otherwise harass another user; impersonate another or use the site for any commercial purposes; or interfere with or disrupt the operation of this site, or disobey any reasonable requirements, procedures or policies imposed by Srtutorbureau.com from time to time.</li>
					  
					  <li>Srtutorbureau.com shall not be held responsible for any interactions/ passing of information(s) etc. between any user / member / visitor via e-mail, chat or any other mediation with another member / user / visitor and Srtutorbureau.com has no obligation, to monitor any such disputes between arising between the users/members and Srtutorbureau.com shall not be party to such dispute/litigation etc.</li>
					  
					  <li>Srtutorbureau.com reserves the absolute right to modify or amend or delete, in any manner as it deems fit, any content of any listing placed on Srtutorbureau.com in order to ensure compliance with the rules of such placement (which may include, without limitation, the efforts and initiatives by Srtutorbureau.com to maximize response to listings), prima facie accuracy and the prevailing laws in force for the time being, especially those relating to prohibition of any indecent, unlawful or obscene content.</li>
					  
					  <li>Websites to which links are given on Srtutorbureau.com and the contents thereof are the sole responsibility of the promoters of such websites and Srtutorbureau.com does not, in any manner whatsoever, recommend, authenticate, endorse, verify or certify these websites or any contents or links there. Srtutorbureau.com does not take any responsibility for the privacy practice adopted by such other websites and therefore Srtutorbureau.com recommends its visitors / users to read the privacy statement of each and every website that the user visits. Srtutorbureau.com also does not take any responsibility or endorse the authenticity of the other online services linked with Srtutorbureau.com which may offer various services like a lucky draw win a free trip, win free tickets, astrology, palmistry, numerology etc. The user / member / visitor of Srtutorbureau.com shall at its sole risk and responsibility click and surf on to such other website(s) / links which is/are being displayed in the website of Srtutorbureau.com. The user shall always bear in mind that at a single stroke/click of the mouse on such links/website, it gets connected through the Transmission Control Protocol (TCP) / Internet Protocol (IP) network of the said users and not through the IP of Srtutorbureau.com. Srtutorbureau.com also does not suggest/encourage any of its users/members to furnish personal information and specially the Profile ID / User Name of Srtutorbureau.com to such or any other website(s).</li>
					  
					  <li>	Srtutorbureau.com reserves the right to tie up with partners in future to come up with various other online services. However, Srtutorbureau.com shall never share the information / data collected from its visitors / users with such other partners without taking prior consent / permission from such visitors / users. Srtutorbureau.com in such circumstances suggests that its visitors / users peruse the privacy policy of such partners before giving their consent or before furnishing personal identifiable information with such other website(s). Srtutorbureau.com is not privy to the privacy practice adopted by its partners and does not take the responsibility in respect of the privacy policy adopted / to be adopted by such partners. Srtutorbureau.com also reserves the right to use / share the information / data collected from its users with its partners if a user / visitor have already made public the information / data to Srtutorbureau.com visitors.</li>
					  
					  <li>Srtutorbureau.com hereby specifically disclaims any liabilities or warranties or guarantees, express, specific or implied, which may be attributable, in any manner whatsoever, to the existence, promotion, canvassing, contents, links, information or discontinuance of Srtutorbureau.com. No claims for damages, restitution, loss-of-profits, or any other pecuniary, civil or criminal cases as per court of law in force, which may be said to arise out, or on account, of any matter contained or related to Srtutorbureau.com, shall lie or be deemed to be sustainable against Srtutorbureau.com</li>
					  
					  <li>	Srtutorbureau.com assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of User and/or Member communications. Srtutorbureau.com is not responsible for any problems or technical malfunction of any telephone network or lines, computer on-line-systems, servers or providers, computer equipment, software, failure of e-mail or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to Users and/or Members or to any other person's computer related to or resulting from participating or downloading materials /information from the web-site.</li>
					  
					  <li>Srtutorbureau.com reserves the absolute right to modify, amend, change, curtail or expand any privileges, rights, benefits, liabilities or responsibilities of Paid Members or Free Members as it deems fit and these will be instantly applicable to all past, present and future Members.</li>
					  
					  <li>	The User is solely and exclusively responsible for maintaining confidentiality of the User password and User identification and all activities and transmission performed by the User through his/her user identification and shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions and Srtutorbureau.com assumes no responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line. Srtutorbureau.com uses the maximum care as is possible to ensure that all or any data / information does not fall in the wrong hands. (The weak link in credit card transaction is securely storing the data once received; therefore Srtutorbureau.com does not store or keep credit card data in a location that is accessible via the Internet. Once a credit card transaction has been completed, all credit card data is moved off-line only to ensure that the data/credit card information received is not accessible to anyone after completion of the on-line transaction and to ensure the maximum security for the personal information of the user.) (Please check)</li>
					  
					  <li>	Srtutorbureau.com does not warrant that the Service will be uninterrupted or error-free. Srtutorbureau.com provides the service on an "as is" basis. However, Srtutorbureau.com will take all reasonable steps to provide its users with error free, uninterrupted user experience. There may be delays, omissions, and interruptions in the availability of the Service. The user/member acknowledges that use of the Service is solely at their cost and risk. Srtutorbureau.com is not responsible for suspension of the Service, irrespective of the reason for such interruption / suspension. Any claim shall be limited to the amount paid, if any, for use of the Service during the previous prescribed months. Srtutorbureau.com may discontinue or change the Service or its availability at any time, and the user/member is also free to stop availing the Service at any time.</li>
					  
					  <li>	Srtutorbureau.com has complete rights in the Srtutorbureau.com Site and the Srtutorbureau.com Service. The Site contains copyrighted material, trademarks, and other proprietary information of its owners and its licensors. Except for that information which is in the public domain or for any other information/display for which permission have not been obtained from Srtutorbureau.com, Srtutorbureau.com reserves the right to proceed/initiate appropriate steps under the prevailing the law against infringement of the said proprietary rights for illegal copying, modifying, publishing, transmitting, distributing, performing, displaying, or selling of any such proprietary information.</li>
					  
					  <li>Srtutorbureau.com reserves the right to verify the authenticity of Content posted on the site. Srtutorbureau.com if circumstances warrant may call for / ask any of its members / users to provide documentary or other form of evidence supporting the information / Content posted on the site and the user / member, without any protest shall produce such documentary / other evidence in support of such information and if the member / user fails to produce such information within a reasonable or stipulated time frame, Srtutorbureau.com may, in its sole discretion, terminate such Membership and forfeit the advance without a refund and take appropriate steps under the provisions of court of law in force.</li>
					  
					  <li>Srtutorbureau.com also reserves the right to investigate and take appropriate legal action without limitation at its sole discretion against any user / member / visitors who violates / misuses the on-line services and terminating the policy or Membership of such violators who promote information that is false, misleading or promote illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous or promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated data or links to pirated data files or provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses or solicits passwords or personal identifying information for commercial or unlawful purposes from other users and/or engages in commercial activities and / or sales promotion such as contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
					  
					  <li>The visitor/user while surfing the Site shall not post, transmit or otherwise distribute information constituting or encouraging conduct that would constitute a criminal offense or give rise to civil liability as per court of law, or otherwise use the Site in any manner whatsoever which is contrary to law or would serve to restrict or inhibit any other user from using or enjoying the Site; post or transmit any information or software which contains a virus, cancelbot, trojan horse, worm or other harmful or disruptive component; upload, post, publish, transmit, reproduce, or distribute in any way, information, software or other material obtained through Srtutorbureau.com which amounts to infringement of copyright, or other intellectual property right, or derivative works with respect hereto, without obtaining permission of the copyright owner or right holder/author.</li>
					  
					  <li>Srtutorbureau.com does not take the responsibility of any information posted by its users/members in the web site, any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights and in case Srtutorbureau.com receives/discovers any such infringement, the said member / user may be called for / asked to furnish / provide evidence / relevant information in support of such display like an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest or a description of the copyrighted work that may have been infringed or information or a written statement that the member/user under bona-fide faith belief that the disputed information does not lead to any authorized use or infringement of copyright etc, under the law or a statement that the user/member is liable to penalty of perjury in case any of the information/statement furnished is proved otherwise or a declaration that the information displayed/posted is accurate and the member /user is itself the copyright owner or authorized to act on the copyright owner's behalf.</li>
					  
					  <li>Srtutorbureau.com has no obligation to monitor the activities of its users/visitors. However, Srtutorbureau.com has the right to monitor the Site electronically from time to time and to disclose any information as necessary to satisfy any law, regulation or other governmental request, to operate the Site properly, or to protect itself or its subscribers. Srtutorbureau.com will not intentionally monitor or disclose any private electronic-mail message unless required by law. Srtutorbureau.com also reserves the right to refuse to post or to remove any information or materials, in whole or in part, that, in its sole discretion, are unacceptable, undesirable, or violate the provisions of court of law.</li>
					  
					  <li>Srtutorbureau.com considers and intends to be subject only to the applicable laws in force for the time being in the NCT of Delhi irrespective of conflicting provisions of law and to the jurisdiction of courts located in Delhi alone.</li>
					
				 </div>
      </section>
    </div>
  );
};

export default PlansAndTerms;
